






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import CommonModule from '@/store/modules/common'
import EGiftPurchaseModule from '@/store/modules/gift-purchase-e-gift'

@Component({
  components: {}
})
export default class EGiftPurchaseStart extends Vue {
  eGiftPurchaseModule: EGiftPurchaseModule = getModule(
    EGiftPurchaseModule,
    this.$store
  );

  @Prop()
  contactId!: string;

  loaded = false;

  async mounted() {
    await this.commonModule.SetQuickActionTitle('Enter Your E-Gift Details')
    if (this.contactId === undefined) {
      this.$router.push({
        name: 'e-gift-purchase.details'
      })
    }
  }

  @Watch('contactId', { immediate: true })
  async onContactIdChanged(newValue: string) {
    if (newValue !== undefined) {
      await this.$emit('contact-id-populated', newValue)
      this.$router.push({
        name: 'e-gift-purchase.details'
      })
    }
  }

  commonModule: CommonModule = getModule(CommonModule, this.$store);
}
