import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'stripe' })
export default class StripeModule extends VuexModule {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripe: stripe.Stripe | Record<string, any> = {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/ban-types
  elements: stripe.elements.Elements | object = {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  card: stripe.elements.Element | object = {}

  token = ''

  stripeInitialized = false

  cardInitialized = false

  last4 = ''

  brand = ''

  @Mutation
  private SET_Stripe(stripe: stripe.Stripe) {
    this.stripe = stripe
  }

  @Mutation
  private SET_Card(card: stripe.elements.Element) {
    this.card = card
    this.cardInitialized = true
  }

  @Mutation
  private SET_Elements(elements: stripe.elements.Elements) {
    this.elements = elements
    this.stripeInitialized = true
  }

  @Mutation
  private SET_Token(token: string) {
    this.token = token
  }

  @Action
  // eslint-disable-next-line class-methods-use-this
  InitializeStripe() {
    if (!this.stripeInitialized) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY!) as stripe.Stripe
      const elements = stripe.elements()
      this.context.commit('SET_Stripe', stripe)
      this.context.commit('SET_Elements', elements)
    }
  }

  @Action
  CreateCard(elementOptions: stripe.elements.ElementsOptions) {
    if (!this.cardInitialized) {
      const card = (this.elements as stripe.elements.Elements).create('card', elementOptions)
      this.context.commit('SET_Card', card)
    }
  }

  @Mutation
  SET_CardMetaData(input: { brand: string; last4: string }) {
    this.brand = input.brand
    this.last4 = input.last4
  }

  @Action
  async GetToken(tokenOption: stripe.TokenOptions) {
    const result = await this.stripe.createToken(this.card, tokenOption)
    if (result.token !== undefined) {
      this.context.commit('SET_CardMetaData', { brand: result.token.card.brand, last4: result.token.card.last4 })
      return result.token.id
    }
    return null
  }
}
