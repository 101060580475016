























































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import CommonModule from '@/store/modules/common'
import EGiftPurchaseModule from '@/store/modules/gift-purchase-e-gift'
import StripeModule from '@/store/modules/stripe'
import moment from 'moment'

@Component({})
export default class EGiftPurchaseDetail extends Vue {
  eGiftPurchaseModule: EGiftPurchaseModule = getModule(EGiftPurchaseModule, this.$store)

  stripeModule: StripeModule = getModule(StripeModule, this.$store)

  commonModule: CommonModule = getModule(CommonModule, this.$store)

  useCardOnFile = false

  useNewCard = true

  shouldSendEmail = false

  shouldNotSendEmail = false

  showMenu = false

  valid = false

  recipientFirstName = ''

  recipientLastName = ''

  recipientEmailAddress = ''

  purchaserFirstName = ''

  purchaserLastName = ''

  purchaserEmailAddress = ''

  personalizedTo = ''

  personalizedFrom = ''

  personalizedNote = ''

  cardMessage = ''

  minDate = moment().format('YYYY-MM-DD')

  deliveryDate = moment().format('MM/DD/YYYY')

  date = moment().format('YYYY-MM-DD')

  get isCardValid() {
    return this.cardMessage === ''
  }

  get stripeElements() {
    return this.stripeModule.elements
  }

  get stripeCard() {
    return this.stripeModule.card
  }

  get shouldDisplayContactInfo() {
    return this.eGiftPurchaseModule.hasCustomerInfo === false
  }

  get ccLabel() {
    return this.eGiftPurchaseModule.ccInfo
  }

  get notePlaceHolder() {
    if (this.eGiftPurchaseModule.customerContactId !== '') {
      if (this.$vuetify.breakpoint.xs) {
        return 'Happy Holidays! Enjoy. We love you!'
      }
      return 'Happy Holidays! We hope you enjoy this as much as we do!'
    }
    return 'Happy Holidays! Enjoy. We love you!'
  }

  get showMessage() {
    if (this.eGiftPurchaseModule.errorMessage !== '') {
      return true
    }
    return false
  }

  get errorMessage() {
    return this.eGiftPurchaseModule.errorMessage
  }

  async mounted() {
    if (this.eGiftPurchaseModule.code === '') {
      await this.commonModule.SetQuickActionTitle('Enter Your E-Gift Details')
      if (!this.stripeModule.stripeInitialized) {
        this.configureStripe()
      } else {
        // eslint-disable-next-line no-undef
        const element = this.stripeModule.card as stripe.elements.Element
        element.mount('#card-element')
        element.addEventListener('change', this.handleStripeChange)
      }

      this.purchaserFirstName = this.eGiftPurchaseModule.customerContactInfo.firstName
      this.purchaserLastName = this.eGiftPurchaseModule.customerContactInfo.lastName
      this.purchaserEmailAddress = this.eGiftPurchaseModule.customerContactInfo.emailAddress
      this.personalizedNote = this.eGiftPurchaseModule.note
      this.personalizedTo = this.eGiftPurchaseModule.toFriendly
      this.personalizedFrom = this.eGiftPurchaseModule.fromFriendly
      this.recipientFirstName = this.eGiftPurchaseModule.recipientContactInfo.firstName
      this.recipientLastName = this.eGiftPurchaseModule.recipientContactInfo.lastName
      this.recipientEmailAddress = this.eGiftPurchaseModule.recipientContactInfo.emailAddress
      this.shouldSendEmail = this.eGiftPurchaseModule.emailDate.sendEmail
      this.shouldNotSendEmail = !this.eGiftPurchaseModule.emailDate.sendEmail
      this.deliveryDate = moment(this.eGiftPurchaseModule.emailDate.sendDate).format('MM/DD/YYYY')
      this.date = moment(this.eGiftPurchaseModule.emailDate.sendDate).format('YYYY-MM-DD')
    } else {
      this.$router.push({
        name: 'e-gift-purchase.complete'
      })
    }
  }

  @Watch('date')
  onDateChanged(newValue: string) {
    this.deliveryDate = moment(newValue).format('MM/DD/YYYY')
  }

  @Watch('useCardOnFile')
  onUseCardOnFile(newValue: boolean) {
    this.useNewCard = !newValue
  }

  @Watch('useNewCard')
  onUseNewCard(newValue: boolean) {
    this.useCardOnFile = !newValue
  }

  @Watch('shouldNotSendEmail')
  onShouldNotSendEmail(newValue: boolean) {
    this.shouldSendEmail = !newValue
  }

  @Watch('shouldSendEmail')
  onShouldSendEmail(newValue: boolean) {
    this.shouldNotSendEmail = !newValue
  }

  @Watch('shouldDisplayContactInfo')
  onShouldDisplayContactInfo(newValue: boolean) {
    this.useCardOnFile = !newValue
    this.useNewCard = newValue
  }

  @Watch('stripeCard')
  // eslint-disable-next-line no-undef
  onStripeCard(card: stripe.elements.Element) {
    card.mount('#card-element')
    card.addEventListener('change', this.handleStripeChange)
  }

  @Watch('stripeElements')
  onStripeElements() {
    if (!this.stripeModule.cardInitialized) {
      this.stripeModule.CreateCard({
        hidePostalCode: false,

        iconStyle: 'solid',
        style: {
          base: {
            fontSize: '16px',
            iconColor: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              fontFamily: 'Source Sans Pro, sans-serif'
            }
          },
          invalid: {
            color: '#ff5252',
            iconColor: '#ff5252'
          }
        }
      })
    }
  }

  private async purchase() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      await this.eGiftPurchaseModule.SetGiftDetails({
        toFriendly: this.personalizedTo,
        fromFriendly: this.personalizedFrom,
        note: this.personalizedNote,
        emailAddress: this.recipientEmailAddress,
        firstName: this.recipientFirstName,
        lastName: this.recipientLastName
      })

      if (this.shouldDisplayContactInfo) {
        await this.eGiftPurchaseModule.SetPurchaserContactInfo({
          firstName: this.purchaserFirstName,
          lastName: this.purchaserLastName,
          emailAddress: this.purchaserEmailAddress
        })
      }

      await this.eGiftPurchaseModule.SetDeliveryEmailOption({
        sendEmail: this.shouldSendEmail,
        sendDate: moment(this.deliveryDate, 'MM/DD/YYYY').toDate()
      })

      if (this.useNewCard && this.isCardValid) {
        const token: string = await this.stripeModule.GetToken({
          name: `${this.purchaserFirstName} ${this.purchaserLastName}`
        })
        await this.eGiftPurchaseModule.SetCCToken(token)
        if (this.isCardValid && token.startsWith('tok_')) {
          this.$emit('purchase-e-gift')
        }
      } else if (!this.useNewCard) {
        this.$emit('purchase-e-gift')
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private configureStripe() {
    if (!this.stripeModule.stripeInitialized) {
      this.stripeModule.InitializeStripe()
    } else {
      // eslint-disable-next-line no-undef
      const element = this.stripeModule.card as stripe.elements.Element
      element.mount('#card-element')
      element.addEventListener('change', this.handleStripeChange)
    }
  }

  // eslint-disable-next-line no-undef
  private handleStripeChange(event: stripe.elements.ElementChangeResponse | undefined) {
    if (event) {
      if (event.error && event.error.message) {
        this.cardMessage = event.error.message
      } else {
        this.cardMessage = 'Unknown error validating your credit card!'
      }
      if (!event.error) {
        this.cardMessage = ''
      }
    }
  }

  personalizedToRules = [(v: string) => !!v || 'To field is required']

  personalizedFromRules = [(v: string) => !!v || 'From field is required']

  recipientFirstNameRules = [(v: string) => !!v || 'First name is required']

  recipientLastNameRules = [(v: string) => !!v || 'Last name is required']

  recipientEmailAddressRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail appears to be invalid.'
  ]

  purchaserFirstNameRules = [(v: string) => !!v || 'First name is required']

  purchaserLastNameRules = [(v: string) => !!v || 'Last name is required']

  purchaserEmailAddressRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail appears to be invalid.'
  ]

  personalizedNoteRules = [(v: string) => !!v || 'Note is required']
}
