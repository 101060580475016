import { Contact } from '@/modules/common'
import axios from 'axios'

export async function PurchaseEGiftCard(input: {
  purchaser: Contact;
  recipient: Contact;
  purchaserContactId: string | null;
  emailSendDate: Date | null;
  token: string;
  fromFriendly: string;
  toFriendly: string;
  note: string;
}): Promise<{
  error: string;
  code: string;
}> {
  try {
    const result = await axios.post<{
    error: string;
    code: string;
  }>('/api/v0/e-gift-card/purchase', input)
    return result.data
  } catch { return { error: 'Unknown Error Occured', code: '' } }
}

export async function LookupContactDetails(input: {
  contactId: string;
}): Promise<{
  customerContactInfo: Contact;
  contactId: string;
  ccInfo: string;
}> {
  const result = await axios.post<{
    customerContactInfo: Contact;
    contactId: string;
    ccInfo: string;
  }>('/api/v0/e-gift-card/get-contact-info', input)
  return result.data
}
