import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import * as api from '@/apis/e-gifts'
import { Contact } from '@/modules/common'
import moment from 'moment'
import { EGiftPurchaseState } from './types'

@Module({ dynamic: true, store, name: 'gift-purchase-e-gift' })
export default class EGiftPurchaseModule extends VuexModule implements EGiftPurchaseState {
  recipientContactInfo = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    phoneNumber: ''
  }

  toFriendly = ''

  fromFriendly = ''

  note = ''

  stripeToken = ''

  customerContactInfo = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    phoneNumber: ''
  }

  customerContactId = ''

  errorMessage = ''

  code = ''

  ccInfo = ''

  emailDate = {
    sendEmail: true,
    sendDate: moment().toDate()
  }

  get hasCustomerInfo() {
    return this.customerContactId !== ''
  }

  @Mutation
  SET_CustomerDetails(data: { customerContactInfo: Contact; contactId: string; ccInfo: string }) {
    Object.assign(this.customerContactInfo, data.customerContactInfo)
    this.customerContactId = data.contactId
    this.ccInfo = data.ccInfo
  }

  @Action
  async LookupContactDetails(input: { contactId: string }) {
    const result = await api.LookupContactDetails(input)
    this.context.commit('SET_CustomerDetails', result)
  }

  @MutationAction
  // eslint-disable-next-line class-methods-use-this
  async SetGiftDetails(input: {
    toFriendly: string
    fromFriendly: string
    note: string
    firstName: string
    lastName: string
    emailAddress: string
  }) {
    return {
      toFriendly: input.toFriendly,
      fromFriendly: input.fromFriendly,
      note: input.note,
      recipientContactInfo: {
        firstName: input.firstName,
        lastName: input.lastName,
        emailAddress: input.emailAddress
      }
    }
  }

  @MutationAction
  // eslint-disable-next-line class-methods-use-this
  async SetDeliveryEmailOption(input: { sendEmail: boolean; sendDate: Date }) {
    return { emailDate: input }
  }

  @Mutation SET_PurchaseResponse(input: { code: string; errorMessage: string }) {
    this.code = input.code
    this.errorMessage = input.errorMessage
  }

  @Action
  // eslint-disable-next-line class-methods-use-this
  async Purchase() {
    const result = await api.PurchaseEGiftCard({
      purchaser: this.customerContactInfo,
      recipient: this.recipientContactInfo,
      note: this.note,
      emailSendDate: this.emailDate.sendEmail ? moment(this.emailDate.sendDate).toDate() : null,
      fromFriendly: this.fromFriendly,
      toFriendly: this.toFriendly,
      token: this.stripeToken,
      purchaserContactId: this.customerContactId === '' ? null : this.customerContactId
    })
    this.context.commit('SET_PurchaseResponse', { code: result.code, errorMessage: result.error })
    return { code: result.code, errorMessage: result.error }
  }

  @MutationAction
  // eslint-disable-next-line class-methods-use-this
  async SetCCToken(token: string) {
    return { stripeToken: token }
  }

  @MutationAction
  // eslint-disable-next-line class-methods-use-this
  async SetPurchaserContactInfo(input: { firstName: string; lastName: string; emailAddress: string }) {
    return {
      customerContactInfo: {
        firstName: input.firstName,
        lastName: input.lastName,
        emailAddress: input.emailAddress
      }
    }
  }
}
