















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import CommonModule from '@/store/modules/common'
import EGiftPurchaseModule from '@/store/modules/gift-purchase-e-gift'
import StripeModule from '@/store/modules/stripe'
import moment from 'moment'

@Component({
  components: {}
})
export default class EGiftPurchaseStart extends Vue {
  eGiftPurchaseModule: EGiftPurchaseModule = getModule(EGiftPurchaseModule, this.$store)

  commonModule: CommonModule = getModule(CommonModule, this.$store)

  stripeModule: StripeModule = getModule(StripeModule, this.$store)

  get code() {
    return this.eGiftPurchaseModule.code
  }

  get formattedCode() {
    return `${this.eGiftPurchaseModule.code.substring(0, 4)}-${this.eGiftPurchaseModule.code.substring(
      4,
      8
    )}-${this.eGiftPurchaseModule.code.substring(8, 12)}`
  }

  get personalizedTo() {
    return this.eGiftPurchaseModule.toFriendly
  }

  get personalizedFrom() {
    return this.eGiftPurchaseModule.fromFriendly
  }

  get recipientName() {
    return `${this.eGiftPurchaseModule.recipientContactInfo.firstName} ${this.eGiftPurchaseModule.recipientContactInfo.lastName}`
  }

  get purchaserName() {
    return `${this.eGiftPurchaseModule.customerContactInfo.firstName} ${this.eGiftPurchaseModule.customerContactInfo.lastName}`
  }

  get areEmailing() {
    return this.eGiftPurchaseModule.emailDate.sendEmail
  }

  get recipientEmailAddress() {
    return this.eGiftPurchaseModule.recipientContactInfo.emailAddress
  }

  get deliveryDate() {
    return moment(this.eGiftPurchaseModule.emailDate.sendDate).format('MM/DD/YYYY')
  }

  get purchaserEmailAddress() {
    return this.eGiftPurchaseModule.customerContactInfo.emailAddress
  }

  get note() {
    return this.eGiftPurchaseModule.note
  }

  get cardInfo() {
    if (this.eGiftPurchaseModule.stripeToken === '') {
      return this.eGiftPurchaseModule.ccInfo
    }
    return `${this.stripeModule.brand} ending in ${this.stripeModule.last4}`
  }

  get placeAnother() {
    if (this.eGiftPurchaseModule.customerContactId !== '') {
      return `https://www.steakchop.com/apps/e-gift-purchase/start/${this.eGiftPurchaseModule.customerContactId}`
    }
    return 'https://www.steakchop.com/apps/e-gift-purchase/start'
  }

  async mounted() {
    await this.commonModule.SetQuickActionTitle('Your E-Gift Receipt')
  }
}
