


























import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import EGiftPurchaseModule from '@/store/modules/gift-purchase-e-gift'

@Component({
  components: {}
})
export default class EGiftPurchase extends Vue {
  eGiftPurchaseModule: EGiftPurchaseModule = getModule(
    EGiftPurchaseModule,
    this.$store
  );

  message = '';

  overlay = false;

  async toggleOverlay(input: { message: string}) {
    this.message = input.message
    this.overlay = !this.overlay
  }

  async lookupContact(contactId: string) {
    await this.toggleOverlay({ message: 'Please Wait: Looking up your account details.' })
    await this.eGiftPurchaseModule.LookupContactDetails({ contactId })
    await this.toggleOverlay({ message: '' })
  }

  async purchaseEGift() {
    await this.toggleOverlay({ message: 'Please Wait: Processing Your Order.' })
    const response = await this.eGiftPurchaseModule.Purchase()
    await this.toggleOverlay({ message: '' })
    if (response.code !== null && response.code !== '') {
      this.$router.push({
        name: 'e-gift-purchase.complete'
      })
    }
  }
}
